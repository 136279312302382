import {
  ConsentContent,
  ConsentRecord,
} from '@verily-src/verily1-protos/consent/common/bff/api/v1/consent';
import {createContext, useContext, useState} from 'react';

export type ConsentInitialState = {
  consentContent?: ConsentContent;
  consentRecord?: ConsentRecord;
  pdfLoaded?: boolean;
  clientContentLoaded?: boolean;
  pendingSubmission?: boolean;
};

export type ConsentState = {
  consentContent?: ConsentContent;
  setConsentContent?: (consentContent: ConsentContent) => void;
  consentRecord?: ConsentRecord;
  setConsentRecord?: (consentRecord: ConsentRecord) => void;
  pdfLoaded?: boolean;
  setPdfLoaded?: (pdfLoaded: boolean) => void;
  clientContentLoaded?: boolean;
  setClientContentLoaded?: (clientContentLoaded: boolean) => void;
  pendingSubmission?: boolean;
  setPendingSubmission?: (pendingSubmission: boolean) => void;
};

export const defaultConsentState: ConsentInitialState = {
  consentContent: undefined,
  consentRecord: undefined,
  pdfLoaded: false,
  clientContentLoaded: false,
  pendingSubmission: false,
};

export function createConsentState(
  consentInitialState = defaultConsentState
): ConsentState {
  const [consentContent, setConsentContent] = useState(
    consentInitialState.consentContent
  );
  const [consentRecord, setConsentRecord] = useState(
    consentInitialState.consentRecord
  );
  const [pdfLoaded, setPdfLoaded] = useState(consentInitialState.pdfLoaded);
  const [clientContentLoaded, setClientContentLoaded] = useState(
    consentInitialState.clientContentLoaded
  );
  const [pendingSubmission, setPendingSubmission] = useState(
    consentInitialState.pendingSubmission
  );

  return {
    consentContent,
    setConsentContent,
    consentRecord,
    setConsentRecord,
    pdfLoaded,
    setPdfLoaded,
    clientContentLoaded,
    setClientContentLoaded,
    pendingSubmission,
    setPendingSubmission,
  };
}

export const StateContext = createContext<ConsentState>(
  defaultConsentState as ConsentState
);

export function useStateContext() {
  return useContext(StateContext);
}
