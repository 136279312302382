import {IconButton, IconButtonProps} from '@mui/material';
import {DARK_GREEN} from './colors';

export const focusButtonStyle = {
  outline: `2px solid ${DARK_GREEN}`,
};

export default function FocusableIconButton(props: IconButtonProps) {
  const {children} = props;

  return (
    <IconButton
      {...props}
      sx={{
        ...props.sx,
        ':focus-visible': focusButtonStyle,
      }}
    >
      {children}
    </IconButton>
  );
}
