import {useEffect, useState} from 'react';
import {useStateContext} from './state';

export function useScreenWidth() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, []);
  return screenWidth;
}

export function useThrowAsyncError() {
  const [, setState] = useState();

  return (error: Error) => {
    setState(() => {
      throw error;
    });
  };
}

export function useIsLoading() {
  const {pdfLoaded, clientContentLoaded, pendingSubmission} = useStateContext();
  const [loading, setLoading] = useState(
    !pdfLoaded || !clientContentLoaded || pendingSubmission
  );

  useEffect(() => {
    setLoading(!pdfLoaded || !clientContentLoaded || pendingSubmission);
  }, [pdfLoaded, clientContentLoaded, pendingSubmission]);

  return loading;
}

export function useSnackbarManager(
  isOpenToStart = false,
  defaultMessage: React.ReactNode = ''
): [
  React.ReactNode,
  React.Dispatch<React.SetStateAction<React.ReactNode>>,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
] {
  const [message, setMessage] = useState<React.ReactNode>(defaultMessage);
  const [isOpen, setIsOpen] = useState(isOpenToStart);
  return [message, setMessage, isOpen, setIsOpen];
}

export function useOnTouch(onTouch: Function) {
  useEffect(() => {
    const onTouchCallback = () => {
      onTouch();
    };
    window.addEventListener('touchstart', onTouchCallback);
    return () => {
      window.removeEventListener('touchstart', onTouchCallback);
    };
  }, []);
}
