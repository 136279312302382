import {
  CssBaseline,
  Experimental_CssVarsProvider,
  experimental_extendTheme,
  ThemeProvider,
} from '@mui/material';
import {ConsumerLightTheme} from '@verily-src/react-design-system';
import {createContext, useContext} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {BrowserRouter as Router} from 'react-router-dom';
import Error from '../components/error/Error';
import {ConsentRpcUtils} from '../utils/protoUtils';
type AppProviderProps = {
  children: React.ReactNode;
  rpcUtils: ConsentRpcUtils;
};

export const RpcContext = createContext<ConsentRpcUtils>({} as ConsentRpcUtils);

export function useRpcContext() {
  return useContext(RpcContext);
}

const cssVarsProvidedTheme = experimental_extendTheme({
  cssVarPrefix: 'vds',
  ...ConsumerLightTheme,
});

export const AppProvider = ({children, rpcUtils}: AppProviderProps) => {
  return (
    <ThemeProvider theme={ConsumerLightTheme}>
      <Experimental_CssVarsProvider theme={cssVarsProvidedTheme}>
        <RpcContext.Provider value={rpcUtils}>
          <CssBaseline />
          <ErrorBoundary FallbackComponent={Error}>
            <Router>{children}</Router>
          </ErrorBoundary>
        </RpcContext.Provider>
      </Experimental_CssVarsProvider>
    </ThemeProvider>
  );
};
