/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "consent/bff/api/v1/consent_bff.proto" (package "consent.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ErrorContent } from "../../../common/bff/api/v1/consent";
import { ConsentContent } from "../../../common/bff/api/v1/consent";
import { Empty } from "../../../../google/protobuf/empty";
import { ClientConsentContent } from "../../../common/bff/api/v1/consent";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { ConsentRecord } from "../../../common/bff/api/v1/consent";
/**
 * Request proto for retrieving localized error content.
 *
 * @generated from protobuf message consent.bff.api.v1.GetErrorContentRequest
 */
export interface GetErrorContentRequest {
    /**
     * Locale for content.
     *
     * @generated from protobuf field: string locale = 1;
     */
    locale: string;
}
/**
 * Request proto for previewing a consent.
 *
 * @generated from protobuf message consent.bff.api.v1.GetPreviewConsentContentRequest
 */
export interface GetPreviewConsentContentRequest {
    /**
     * Unique identifier for a consent. Must correspond to a content id in Consent
     * Studio.
     *
     * @generated from protobuf field: string consent_id = 1;
     */
    consentId: string;
    /**
     * The revision of the consent to preview. Must correspond to a revision in
     * Consent Studio.
     *
     * @generated from protobuf field: int64 revision = 2;
     */
    revision: bigint;
    /**
     * Locale of consent to display to the user.
     *
     * @generated from protobuf field: string locale = 3;
     */
    locale: string;
}
/**
 * Request proto for viewing a consent.
 *
 * @generated from protobuf message consent.bff.api.v1.ViewConsentRequest
 */
export interface ViewConsentRequest {
    /**
     * Record ID of the consent content to be viewed.
     *
     * @generated from protobuf field: string consent_record_id = 1;
     */
    consentRecordId: string;
}
/**
 * Request proto for submitting a consent.
 *
 * @generated from protobuf message consent.bff.api.v1.SubmitConsentRequest
 */
export interface SubmitConsentRequest {
    /**
     * Record ID of the consent content to be submitted.
     *
     * @generated from protobuf field: string consent_record_id = 1;
     */
    consentRecordId: string;
    /**
     * Legal name as provided by the user.
     *
     * @generated from protobuf field: string legal_name = 2;
     */
    legalName: string;
    /**
     * PNG representation of the user's signature.
     *
     * @generated from protobuf field: bytes signature_image = 3;
     */
    signatureImage: Uint8Array;
    /**
     * Reason IDs of signing reasons checked by the user.
     *
     * @generated from protobuf field: repeated string reason_ids = 4;
     */
    reasonIds: string[];
    /**
     * True for typed signatures. Consent state will be set to pending_reauth to trigger reauth flow.
     *
     * @generated from protobuf field: bool requires_reauth = 5;
     */
    requiresReauth: boolean;
}
/**
 * Request proto for submitting a consent marked pending.
 *
 * @generated from protobuf message consent.bff.api.v1.SubmitPendingConsentRequest
 */
export interface SubmitPendingConsentRequest {
    /**
     * Record ID of the consent content to be submitted.
     *
     * @generated from protobuf field: string consent_record_id = 1;
     */
    consentRecordId: string;
}
/**
 * Response proto for submitting a consent.
 *
 * @generated from protobuf message consent.bff.api.v1.SubmitConsentResponse
 */
export interface SubmitConsentResponse {
    /**
     * The url to redirect to after a consent is submitted.
     *
     * @generated from protobuf field: string consent_submitted_redirect_url = 1;
     */
    consentSubmittedRedirectUrl: string;
}
/**
 * Request proto for retrieving client consent content.
 *
 * @generated from protobuf message consent.bff.api.v1.GetClientConsentContentRequest
 */
export interface GetClientConsentContentRequest {
    /**
     * Record ID of the consent content to be retrieved.
     *
     * @generated from protobuf field: string consent_record_id = 1;
     */
    consentRecordId: string;
}
/**
 * Request proto for declining a consent.
 *
 * @generated from protobuf message consent.bff.api.v1.DeclineConsentRequest
 */
export interface DeclineConsentRequest {
    /**
     * Record ID of the consent content to be retrieved.
     *
     * @generated from protobuf field: string consent_record_id = 1;
     */
    consentRecordId: string;
}
/**
 * Request proto for withdrawing a consent.
 *
 * @generated from protobuf message consent.bff.api.v1.WithdrawConsentRequest
 */
export interface WithdrawConsentRequest {
    /**
     * Record ID of the consent content to be retrieved.
     *
     * @generated from protobuf field: string consent_record_id = 1;
     */
    consentRecordId: string;
}
/**
 * Proto wrapper for consent declined redirect url.
 *
 * @generated from protobuf message consent.bff.api.v1.DeclineConsentResponse
 */
export interface DeclineConsentResponse {
    /**
     * The url to redirect to after a consent is declined.
     *
     * @generated from protobuf field: string consent_declined_redirect_url = 1;
     */
    consentDeclinedRedirectUrl: string;
}
/**
 * Request proto to create a consent record for a user.
 *
 * @generated from protobuf message consent.bff.api.v1.CreateConsentRecordRequest
 */
export interface CreateConsentRecordRequest {
    /**
     * The consent record to create.
     *
     * @generated from protobuf field: consent.common.bff.api.v1.ConsentRecord consent_record = 1;
     */
    consentRecord?: ConsentRecord;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetErrorContentRequest$Type extends MessageType<GetErrorContentRequest> {
    constructor() {
        super("consent.bff.api.v1.GetErrorContentRequest", [
            { no: 1, name: "locale", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } }
        ]);
    }
    create(value?: PartialMessage<GetErrorContentRequest>): GetErrorContentRequest {
        const message = { locale: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetErrorContentRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetErrorContentRequest): GetErrorContentRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locale */ 1:
                    message.locale = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetErrorContentRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locale = 1; */
        if (message.locale !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locale);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.bff.api.v1.GetErrorContentRequest
 */
export const GetErrorContentRequest = new GetErrorContentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPreviewConsentContentRequest$Type extends MessageType<GetPreviewConsentContentRequest> {
    constructor() {
        super("consent.bff.api.v1.GetPreviewConsentContentRequest", [
            { no: 1, name: "consent_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } },
            { no: 2, name: "revision", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/, options: { "validate.rules": { int64: { gte: "1" } } } },
            { no: 3, name: "locale", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } }
        ]);
    }
    create(value?: PartialMessage<GetPreviewConsentContentRequest>): GetPreviewConsentContentRequest {
        const message = { consentId: "", revision: 0n, locale: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPreviewConsentContentRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPreviewConsentContentRequest): GetPreviewConsentContentRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string consent_id */ 1:
                    message.consentId = reader.string();
                    break;
                case /* int64 revision */ 2:
                    message.revision = reader.int64().toBigInt();
                    break;
                case /* string locale */ 3:
                    message.locale = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPreviewConsentContentRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string consent_id = 1; */
        if (message.consentId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.consentId);
        /* int64 revision = 2; */
        if (message.revision !== 0n)
            writer.tag(2, WireType.Varint).int64(message.revision);
        /* string locale = 3; */
        if (message.locale !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.locale);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.bff.api.v1.GetPreviewConsentContentRequest
 */
export const GetPreviewConsentContentRequest = new GetPreviewConsentContentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ViewConsentRequest$Type extends MessageType<ViewConsentRequest> {
    constructor() {
        super("consent.bff.api.v1.ViewConsentRequest", [
            { no: 1, name: "consent_record_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ViewConsentRequest>): ViewConsentRequest {
        const message = { consentRecordId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ViewConsentRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ViewConsentRequest): ViewConsentRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string consent_record_id */ 1:
                    message.consentRecordId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ViewConsentRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string consent_record_id = 1; */
        if (message.consentRecordId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.consentRecordId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.bff.api.v1.ViewConsentRequest
 */
export const ViewConsentRequest = new ViewConsentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitConsentRequest$Type extends MessageType<SubmitConsentRequest> {
    constructor() {
        super("consent.bff.api.v1.SubmitConsentRequest", [
            { no: 1, name: "consent_record_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "legal_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "signature_image", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 4, name: "reason_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "requires_reauth", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SubmitConsentRequest>): SubmitConsentRequest {
        const message = { consentRecordId: "", legalName: "", signatureImage: new Uint8Array(0), reasonIds: [], requiresReauth: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SubmitConsentRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SubmitConsentRequest): SubmitConsentRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string consent_record_id */ 1:
                    message.consentRecordId = reader.string();
                    break;
                case /* string legal_name */ 2:
                    message.legalName = reader.string();
                    break;
                case /* bytes signature_image */ 3:
                    message.signatureImage = reader.bytes();
                    break;
                case /* repeated string reason_ids */ 4:
                    message.reasonIds.push(reader.string());
                    break;
                case /* bool requires_reauth */ 5:
                    message.requiresReauth = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SubmitConsentRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string consent_record_id = 1; */
        if (message.consentRecordId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.consentRecordId);
        /* string legal_name = 2; */
        if (message.legalName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.legalName);
        /* bytes signature_image = 3; */
        if (message.signatureImage.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.signatureImage);
        /* repeated string reason_ids = 4; */
        for (let i = 0; i < message.reasonIds.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.reasonIds[i]);
        /* bool requires_reauth = 5; */
        if (message.requiresReauth !== false)
            writer.tag(5, WireType.Varint).bool(message.requiresReauth);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.bff.api.v1.SubmitConsentRequest
 */
export const SubmitConsentRequest = new SubmitConsentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitPendingConsentRequest$Type extends MessageType<SubmitPendingConsentRequest> {
    constructor() {
        super("consent.bff.api.v1.SubmitPendingConsentRequest", [
            { no: 1, name: "consent_record_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SubmitPendingConsentRequest>): SubmitPendingConsentRequest {
        const message = { consentRecordId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SubmitPendingConsentRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SubmitPendingConsentRequest): SubmitPendingConsentRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string consent_record_id */ 1:
                    message.consentRecordId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SubmitPendingConsentRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string consent_record_id = 1; */
        if (message.consentRecordId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.consentRecordId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.bff.api.v1.SubmitPendingConsentRequest
 */
export const SubmitPendingConsentRequest = new SubmitPendingConsentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitConsentResponse$Type extends MessageType<SubmitConsentResponse> {
    constructor() {
        super("consent.bff.api.v1.SubmitConsentResponse", [
            { no: 1, name: "consent_submitted_redirect_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SubmitConsentResponse>): SubmitConsentResponse {
        const message = { consentSubmittedRedirectUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SubmitConsentResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SubmitConsentResponse): SubmitConsentResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string consent_submitted_redirect_url */ 1:
                    message.consentSubmittedRedirectUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SubmitConsentResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string consent_submitted_redirect_url = 1; */
        if (message.consentSubmittedRedirectUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.consentSubmittedRedirectUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.bff.api.v1.SubmitConsentResponse
 */
export const SubmitConsentResponse = new SubmitConsentResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetClientConsentContentRequest$Type extends MessageType<GetClientConsentContentRequest> {
    constructor() {
        super("consent.bff.api.v1.GetClientConsentContentRequest", [
            { no: 1, name: "consent_record_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetClientConsentContentRequest>): GetClientConsentContentRequest {
        const message = { consentRecordId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetClientConsentContentRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetClientConsentContentRequest): GetClientConsentContentRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string consent_record_id */ 1:
                    message.consentRecordId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetClientConsentContentRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string consent_record_id = 1; */
        if (message.consentRecordId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.consentRecordId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.bff.api.v1.GetClientConsentContentRequest
 */
export const GetClientConsentContentRequest = new GetClientConsentContentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeclineConsentRequest$Type extends MessageType<DeclineConsentRequest> {
    constructor() {
        super("consent.bff.api.v1.DeclineConsentRequest", [
            { no: 1, name: "consent_record_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeclineConsentRequest>): DeclineConsentRequest {
        const message = { consentRecordId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeclineConsentRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeclineConsentRequest): DeclineConsentRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string consent_record_id */ 1:
                    message.consentRecordId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeclineConsentRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string consent_record_id = 1; */
        if (message.consentRecordId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.consentRecordId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.bff.api.v1.DeclineConsentRequest
 */
export const DeclineConsentRequest = new DeclineConsentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WithdrawConsentRequest$Type extends MessageType<WithdrawConsentRequest> {
    constructor() {
        super("consent.bff.api.v1.WithdrawConsentRequest", [
            { no: 1, name: "consent_record_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<WithdrawConsentRequest>): WithdrawConsentRequest {
        const message = { consentRecordId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WithdrawConsentRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WithdrawConsentRequest): WithdrawConsentRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string consent_record_id */ 1:
                    message.consentRecordId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WithdrawConsentRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string consent_record_id = 1; */
        if (message.consentRecordId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.consentRecordId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.bff.api.v1.WithdrawConsentRequest
 */
export const WithdrawConsentRequest = new WithdrawConsentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeclineConsentResponse$Type extends MessageType<DeclineConsentResponse> {
    constructor() {
        super("consent.bff.api.v1.DeclineConsentResponse", [
            { no: 1, name: "consent_declined_redirect_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeclineConsentResponse>): DeclineConsentResponse {
        const message = { consentDeclinedRedirectUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeclineConsentResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeclineConsentResponse): DeclineConsentResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string consent_declined_redirect_url */ 1:
                    message.consentDeclinedRedirectUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeclineConsentResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string consent_declined_redirect_url = 1; */
        if (message.consentDeclinedRedirectUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.consentDeclinedRedirectUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.bff.api.v1.DeclineConsentResponse
 */
export const DeclineConsentResponse = new DeclineConsentResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateConsentRecordRequest$Type extends MessageType<CreateConsentRecordRequest> {
    constructor() {
        super("consent.bff.api.v1.CreateConsentRecordRequest", [
            { no: 1, name: "consent_record", kind: "message", T: () => ConsentRecord }
        ]);
    }
    create(value?: PartialMessage<CreateConsentRecordRequest>): CreateConsentRecordRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateConsentRecordRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateConsentRecordRequest): CreateConsentRecordRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* consent.common.bff.api.v1.ConsentRecord consent_record */ 1:
                    message.consentRecord = ConsentRecord.internalBinaryRead(reader, reader.uint32(), options, message.consentRecord);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateConsentRecordRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* consent.common.bff.api.v1.ConsentRecord consent_record = 1; */
        if (message.consentRecord)
            ConsentRecord.internalBinaryWrite(message.consentRecord, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.bff.api.v1.CreateConsentRecordRequest
 */
export const CreateConsentRecordRequest = new CreateConsentRecordRequest$Type();
/**
 * @generated ServiceType for protobuf service consent.bff.api.v1.ConsentBffService
 */
export const ConsentBffService = new ServiceType("consent.bff.api.v1.ConsentBffService", [
    { name: "GetClientConsentContent", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: GetClientConsentContentRequest, O: ClientConsentContent },
    { name: "DeclineConsent", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: DeclineConsentRequest, O: DeclineConsentResponse },
    { name: "WithdrawConsent", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: WithdrawConsentRequest, O: Empty },
    { name: "SubmitConsent", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: SubmitConsentRequest, O: SubmitConsentResponse },
    { name: "SubmitPendingConsent", options: { "ciam.annotations.permissions": { inputs: { target: "signConsent" }, ciam: ["CIAM_SIGN_DOC_WITH_REAUTH"] } }, I: SubmitPendingConsentRequest, O: SubmitConsentResponse },
    { name: "ViewConsent", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: ViewConsentRequest, O: Empty },
    { name: "GetPreviewConsentContent", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: GetPreviewConsentContentRequest, O: ConsentContent },
    { name: "CreateConsentRecord", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: CreateConsentRecordRequest, O: ConsentRecord },
    { name: "GetErrorContent", options: { "ciam.annotations.permissions": { allowUnauthenticated: true } }, I: GetErrorContentRequest, O: ErrorContent }
]);
