import {CircularProgress} from '@mui/material';
import {useRoutes} from 'react-router-dom';
import {useIsLoading} from '../../../common/mfe/src/app/hooks';
import './index.scss';
import {protectedRoutes} from './protected';

export const AppRoutes = () => {
    const element = useRoutes(protectedRoutes);

    const isLoading = useIsLoading();

    return (
        <>
            {isLoading && (
                <div className="app-loading">{<CircularProgress />}</div>
            )}
            {element}
        </>
    );
};
