import {Typography} from '@mui/material';
import {api} from '@verily-src/phaf-unified-api';
import {ArrowBackIcon, Tooltip} from '@verily-src/react-design-system';
import {useEffect, useState} from 'react';
import {FallbackProps} from 'react-error-boundary';
import {MUTED_GREY} from '../../theme/colors';
import './Error.scss';
import ErrorImage from './ErrorImage';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {type api as VerilyMeApi} from '@verily-src/verily-me-api';
import {ErrorContent} from '@verily-src/verily1-protos/consent/common/bff/api/v1/consent';
import {useRpcContext} from '../../app/provider';
import FocusableIconButton from '../../theme/FocusableIconButton';
type ErrorProps = Partial<FallbackProps> & {
  errorMessage?: string;
};

function Error(props: ErrorProps) {
  const {error, errorMessage} = props;
  const [localizedContent, setLocalizedContent] = useState<ErrorContent>();
  const rpcUtils = useRpcContext();
  useEffect(() => {
    rpcUtils
      .getErrorContent(getBrowserLocale())
      .then(setLocalizedContent)
      .catch((e: any) =>
        console.error(`Unable to retrieve localized error content: ${e}`)
      );
  }, []);

  function getErrorTitle(): string {
    if (error && error?.name) {
      return error.name;
    }
    return (
      localizedContent?.defaultErrorMessage ?? 'Oops, something went wrong'
    );
  }

  function getErrorString(): string {
    if (errorMessage) {
      return errorMessage;
    }
    if (error && error?.message) {
      return error.message;
    }
    return '';
  }

  function isVerilyMeBundle() {
    return (
      window.location.href.includes('me/bundle/consent') &&
      !!verilyMeApi().bundle
    );
  }

  function verilyMeApi() {
    return api as typeof VerilyMeApi;
  }

  return (
    <>
      <div className="error-header">
        <Tooltip title={localizedContent?.backButtonTooltip ?? 'back'}>
          <FocusableIconButton
            aria-label={localizedContent?.backButtonLabel ?? 'back'}
            data-testid="back-button"
            onClick={() => {
              if (isVerilyMeBundle()) {
                verilyMeApi().bundle.back();
                return;
              }
              window.history.back();
            }}
          >
            <ArrowBackIcon />
          </FocusableIconButton>
        </Tooltip>
      </div>
      <div className="error-body">
        <div className="error-image">{ErrorImage}</div>
        <div className="error-content">
          <Typography variant="display5">{getErrorTitle()}</Typography>
          <Typography variant="body1" color={MUTED_GREY}>
            {getErrorString()}
          </Typography>
        </div>
      </div>
    </>
  );
}

function getBrowserLocale() {
  if (navigator.languages !== undefined) return navigator.languages[0];
  return navigator.language;
}

export default Error;
