import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';
import {RpcOptions} from '@protobuf-ts/runtime-rpc';
import {Auth} from '@verily-src/auth';
import {
    CreateConsentRecordRequest,
    DeclineConsentRequest,
    GetClientConsentContentRequest,
    GetErrorContentRequest,
    GetPreviewConsentContentRequest,
    SubmitConsentRequest,
    SubmitPendingConsentRequest,
    ViewConsentRequest,
    WithdrawConsentRequest,
} from '@verily-src/verily1-protos/consent/bff/api/v1/consent_bff';
import {ConsentBffServiceClient} from '@verily-src/verily1-protos/consent/bff/api/v1/consent_bff.client';
import {
    ClientConsentContent,
    ConsentContent,
    ConsentRecord,
    ErrorContent,
} from '@verily-src/verily1-protos/consent/common/bff/api/v1/consent';
import {ConsentRpcUtils} from '../../../common/mfe/src/utils/protoUtils';

export const rpcUtils: ConsentRpcUtils = {
    createConsentRecord: createConsentRecord,
    getPreviewConsentContent: getPreviewConsentContent,
    getClientConsentContent: getClientConsentContent,
    submitConsent: submitConsent,
    submitPendingConsent: submitPendingConsent,
    declineConsent: declineConsent,
    withdrawConsent: withdrawConsent,
    viewConsent: viewConsent,
    getErrorContent: getErrorContent,
    getRpcOptions: getRpcOptions,
};

export async function createConsentRecord(
    consentRecord: ConsentRecord
): Promise<ConsentRecord> {
    const transport = new GrpcWebFetchTransport({
        baseUrl: getHostUrl(),
    });
    const bffClient = new ConsentBffServiceClient(transport);
    const response = await bffClient.createConsentRecord(
        CreateConsentRecordRequest.create({consentRecord}),
        await getRpcOptions()
    );
    return response.response;
}
/**
 * BFF method to fetch a locale consent content for preview.
 * @param consentId - the unique identifier associated with consent content
 * @param revision - the revision associated with consent content
 * @param locale  - the locale associated with consent content
 * @returns A promise containing the consent content.
 */
export async function getPreviewConsentContent(
    consentId: string,
    revision: number,
    locale: string
): Promise<ConsentContent> {
    const transport = new GrpcWebFetchTransport({
        baseUrl: getHostUrl(),
    });
    const bffClient = new ConsentBffServiceClient(transport);
    const response = bffClient.getPreviewConsentContent(
        GetPreviewConsentContentRequest.create({
            consentId,
            revision: BigInt(revision),
            locale,
        }),
        await getRpcOptions()
    );
    return response.response;
}

/**
 * BFF method that acts as a wrapper for getConsentRecord and getConsentContent.
 * @param consentRecordId - the ID associated with the client content.
 * @returns A promise containing the client content containing the record and ConsentContent.
 */
export async function getClientConsentContent(
    consentRecordId: string
): Promise<ClientConsentContent> {
    const transport = new GrpcWebFetchTransport({
        baseUrl: getHostUrl(),
    });
    const bffClient = new ConsentBffServiceClient(transport);
    const response = bffClient.getClientConsentContent(
        GetClientConsentContentRequest.create({consentRecordId}),
        await getRpcOptions()
    );
    return response.response;
}

export async function submitConsent(
    consentRecordId: string,
    legalName: string,
    signatureImage: string,
    reasonIds: string[],
    requiresReauth: boolean
): Promise<string> {
    const transport = new GrpcWebFetchTransport({
        baseUrl: getHostUrl(),
    });
    const bffClient = new ConsentBffServiceClient(transport);
    const response = await bffClient.submitConsent(
        SubmitConsentRequest.create({
            consentRecordId,
            legalName,
            signatureImage: new TextEncoder().encode(signatureImage),
            reasonIds,
            requiresReauth,
        }),
        await getRpcOptions()
    );
    return response.response.consentSubmittedRedirectUrl;
}

export async function submitPendingConsent(
    consentRecordId: string
): Promise<string> {
    const transport = new GrpcWebFetchTransport({
        baseUrl: getHostUrl(),
    });
    const bffClient = new ConsentBffServiceClient(transport);
    const response = await bffClient.submitPendingConsent(
        SubmitPendingConsentRequest.create({
            consentRecordId,
        }),
        await getRpcOptions()
    );
    return response.response.consentSubmittedRedirectUrl;
}

export async function declineConsent(consentRecordId: string): Promise<string> {
    const transport = new GrpcWebFetchTransport({
        baseUrl: getHostUrl(),
    });
    const bffClient = new ConsentBffServiceClient(transport);
    const response = await bffClient.declineConsent(
        DeclineConsentRequest.create({consentRecordId}),
        await getRpcOptions()
    );
    return response.response.consentDeclinedRedirectUrl;
}

export async function withdrawConsent(consentRecordId: string) {
    const transport = new GrpcWebFetchTransport({
        baseUrl: getHostUrl(),
    });
    const bffClient = new ConsentBffServiceClient(transport);
    await bffClient.withdrawConsent(
        WithdrawConsentRequest.create({consentRecordId}),
        await getRpcOptions()
    );
}

export async function viewConsent(consentRecordId: string) {
    const transport = new GrpcWebFetchTransport({
        baseUrl: getHostUrl(),
    });
    const bffClient = new ConsentBffServiceClient(transport);
    return bffClient.viewConsent(
        ViewConsentRequest.create({consentRecordId}),
        await getRpcOptions()
    );
}

export async function getErrorContent(locale: string): Promise<ErrorContent> {
    const transport = new GrpcWebFetchTransport({
        baseUrl: getHostUrl(),
    });
    const bffClient = new ConsentBffServiceClient(transport);
    const response = await bffClient.getErrorContent(
        GetErrorContentRequest.create({
            locale: locale,
        })
    );
    return response.response;
}

function getHostUrl(): string {
    return `${window.location.protocol}//${window.location.host}/api/`;
}

export async function getRpcOptions(): Promise<RpcOptions> {
    const interceptor = await Auth.getInterceptor();
    const options: RpcOptions = {
        interceptors: [interceptor],
    };
    return options;
}
