// This is used to expose PHAF global declarations.
import '@verily-src/phaf-runtime-helpers';

// Need to hoist public path setting to top so ES6 imports do not come before it.
import './publicPath';

import React from 'react';
import ReactDOM from 'react-dom';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import singleSpaReact from 'single-spa-react';
import Root from './components/Root/Root';
import reportWebVitals from './reportWebVitals';
import './verily-consent-mfe.css';

const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundary() {
        // Customize the root error boundary for your microfrontend here.
        return null;
    },
});

export const {bootstrap, mount, unmount} = lifecycles;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
