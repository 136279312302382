/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "consent/bff/api/v1/consent_bff.proto" (package "consent.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { ConsentBffService } from "./consent_bff";
import type { ErrorContent } from "../../../common/bff/api/v1/consent";
import type { GetErrorContentRequest } from "./consent_bff";
import type { ConsentRecord } from "../../../common/bff/api/v1/consent";
import type { CreateConsentRecordRequest } from "./consent_bff";
import type { ConsentContent } from "../../../common/bff/api/v1/consent";
import type { GetPreviewConsentContentRequest } from "./consent_bff";
import type { ViewConsentRequest } from "./consent_bff";
import type { SubmitPendingConsentRequest } from "./consent_bff";
import type { SubmitConsentResponse } from "./consent_bff";
import type { SubmitConsentRequest } from "./consent_bff";
import type { Empty } from "../../../../google/protobuf/empty";
import type { WithdrawConsentRequest } from "./consent_bff";
import type { DeclineConsentResponse } from "./consent_bff";
import type { DeclineConsentRequest } from "./consent_bff";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ClientConsentContent } from "../../../common/bff/api/v1/consent";
import type { GetClientConsentContentRequest } from "./consent_bff";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * A Backend For Frontend Service for the Consent Renderer Frontend
 *
 * @generated from protobuf service consent.bff.api.v1.ConsentBffService
 */
export interface IConsentBffServiceClient {
    /**
     * Retrieve consent content for the frontend associated with a consent record
     * ID.
     *
     * @generated from protobuf rpc: GetClientConsentContent(consent.bff.api.v1.GetClientConsentContentRequest) returns (consent.common.bff.api.v1.ClientConsentContent);
     */
    getClientConsentContent(input: GetClientConsentContentRequest, options?: RpcOptions): UnaryCall<GetClientConsentContentRequest, ClientConsentContent>;
    /**
     * Decline consent for the frontend associated with consent record ID.
     *
     * @generated from protobuf rpc: DeclineConsent(consent.bff.api.v1.DeclineConsentRequest) returns (consent.bff.api.v1.DeclineConsentResponse);
     */
    declineConsent(input: DeclineConsentRequest, options?: RpcOptions): UnaryCall<DeclineConsentRequest, DeclineConsentResponse>;
    /**
     * Withdraw consent for the frontend associated with consent record ID.
     *
     * @generated from protobuf rpc: WithdrawConsent(consent.bff.api.v1.WithdrawConsentRequest) returns (google.protobuf.Empty);
     */
    withdrawConsent(input: WithdrawConsentRequest, options?: RpcOptions): UnaryCall<WithdrawConsentRequest, Empty>;
    /**
     * Submit consent for the frontend.
     *
     * @generated from protobuf rpc: SubmitConsent(consent.bff.api.v1.SubmitConsentRequest) returns (consent.bff.api.v1.SubmitConsentResponse);
     */
    submitConsent(input: SubmitConsentRequest, options?: RpcOptions): UnaryCall<SubmitConsentRequest, SubmitConsentResponse>;
    /**
     * Submit pending consent for the frontend. Needed for consents that require reauth.
     *
     * @generated from protobuf rpc: SubmitPendingConsent(consent.bff.api.v1.SubmitPendingConsentRequest) returns (consent.bff.api.v1.SubmitConsentResponse);
     */
    submitPendingConsent(input: SubmitPendingConsentRequest, options?: RpcOptions): UnaryCall<SubmitPendingConsentRequest, SubmitConsentResponse>;
    /**
     * View consent for the frontend.
     *
     * @generated from protobuf rpc: ViewConsent(consent.bff.api.v1.ViewConsentRequest) returns (google.protobuf.Empty);
     */
    viewConsent(input: ViewConsentRequest, options?: RpcOptions): UnaryCall<ViewConsentRequest, Empty>;
    /**
     * Retrieve consent content for the frontend preview associated with consent
     * id info.
     *
     * @generated from protobuf rpc: GetPreviewConsentContent(consent.bff.api.v1.GetPreviewConsentContentRequest) returns (consent.common.bff.api.v1.ConsentContent);
     */
    getPreviewConsentContent(input: GetPreviewConsentContentRequest, options?: RpcOptions): UnaryCall<GetPreviewConsentContentRequest, ConsentContent>;
    /**
     * Create a consent record.
     *
     * @generated from protobuf rpc: CreateConsentRecord(consent.bff.api.v1.CreateConsentRecordRequest) returns (consent.common.bff.api.v1.ConsentRecord);
     */
    createConsentRecord(input: CreateConsentRecordRequest, options?: RpcOptions): UnaryCall<CreateConsentRecordRequest, ConsentRecord>;
    /**
     * Get localized error content
     *
     * @generated from protobuf rpc: GetErrorContent(consent.bff.api.v1.GetErrorContentRequest) returns (consent.common.bff.api.v1.ErrorContent);
     */
    getErrorContent(input: GetErrorContentRequest, options?: RpcOptions): UnaryCall<GetErrorContentRequest, ErrorContent>;
}
/**
 * A Backend For Frontend Service for the Consent Renderer Frontend
 *
 * @generated from protobuf service consent.bff.api.v1.ConsentBffService
 */
export class ConsentBffServiceClient implements IConsentBffServiceClient, ServiceInfo {
    typeName = ConsentBffService.typeName;
    methods = ConsentBffService.methods;
    options = ConsentBffService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Retrieve consent content for the frontend associated with a consent record
     * ID.
     *
     * @generated from protobuf rpc: GetClientConsentContent(consent.bff.api.v1.GetClientConsentContentRequest) returns (consent.common.bff.api.v1.ClientConsentContent);
     */
    getClientConsentContent(input: GetClientConsentContentRequest, options?: RpcOptions): UnaryCall<GetClientConsentContentRequest, ClientConsentContent> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetClientConsentContentRequest, ClientConsentContent>("unary", this._transport, method, opt, input);
    }
    /**
     * Decline consent for the frontend associated with consent record ID.
     *
     * @generated from protobuf rpc: DeclineConsent(consent.bff.api.v1.DeclineConsentRequest) returns (consent.bff.api.v1.DeclineConsentResponse);
     */
    declineConsent(input: DeclineConsentRequest, options?: RpcOptions): UnaryCall<DeclineConsentRequest, DeclineConsentResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeclineConsentRequest, DeclineConsentResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Withdraw consent for the frontend associated with consent record ID.
     *
     * @generated from protobuf rpc: WithdrawConsent(consent.bff.api.v1.WithdrawConsentRequest) returns (google.protobuf.Empty);
     */
    withdrawConsent(input: WithdrawConsentRequest, options?: RpcOptions): UnaryCall<WithdrawConsentRequest, Empty> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<WithdrawConsentRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * Submit consent for the frontend.
     *
     * @generated from protobuf rpc: SubmitConsent(consent.bff.api.v1.SubmitConsentRequest) returns (consent.bff.api.v1.SubmitConsentResponse);
     */
    submitConsent(input: SubmitConsentRequest, options?: RpcOptions): UnaryCall<SubmitConsentRequest, SubmitConsentResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<SubmitConsentRequest, SubmitConsentResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Submit pending consent for the frontend. Needed for consents that require reauth.
     *
     * @generated from protobuf rpc: SubmitPendingConsent(consent.bff.api.v1.SubmitPendingConsentRequest) returns (consent.bff.api.v1.SubmitConsentResponse);
     */
    submitPendingConsent(input: SubmitPendingConsentRequest, options?: RpcOptions): UnaryCall<SubmitPendingConsentRequest, SubmitConsentResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<SubmitPendingConsentRequest, SubmitConsentResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * View consent for the frontend.
     *
     * @generated from protobuf rpc: ViewConsent(consent.bff.api.v1.ViewConsentRequest) returns (google.protobuf.Empty);
     */
    viewConsent(input: ViewConsentRequest, options?: RpcOptions): UnaryCall<ViewConsentRequest, Empty> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<ViewConsentRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * Retrieve consent content for the frontend preview associated with consent
     * id info.
     *
     * @generated from protobuf rpc: GetPreviewConsentContent(consent.bff.api.v1.GetPreviewConsentContentRequest) returns (consent.common.bff.api.v1.ConsentContent);
     */
    getPreviewConsentContent(input: GetPreviewConsentContentRequest, options?: RpcOptions): UnaryCall<GetPreviewConsentContentRequest, ConsentContent> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPreviewConsentContentRequest, ConsentContent>("unary", this._transport, method, opt, input);
    }
    /**
     * Create a consent record.
     *
     * @generated from protobuf rpc: CreateConsentRecord(consent.bff.api.v1.CreateConsentRecordRequest) returns (consent.common.bff.api.v1.ConsentRecord);
     */
    createConsentRecord(input: CreateConsentRecordRequest, options?: RpcOptions): UnaryCall<CreateConsentRecordRequest, ConsentRecord> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateConsentRecordRequest, ConsentRecord>("unary", this._transport, method, opt, input);
    }
    /**
     * Get localized error content
     *
     * @generated from protobuf rpc: GetErrorContent(consent.bff.api.v1.GetErrorContentRequest) returns (consent.common.bff.api.v1.ErrorContent);
     */
    getErrorContent(input: GetErrorContentRequest, options?: RpcOptions): UnaryCall<GetErrorContentRequest, ErrorContent> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetErrorContentRequest, ErrorContent>("unary", this._transport, method, opt, input);
    }
}
